import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';

class LayoutDefault extends Component {
  render() {
    return (
      <>
        <Header navPosition="right" className="reveal-from-bottom" />
        <main className="site-content">{this.props.children}</main>
        <Footer />
      </>
    );
  }
}

export default LayoutDefault;
