import React from 'react';

import LayoutDefault from '../components/LayoutDefault';
import Home from './Home';

const img_set = [
  {
    src: require('../assets/images/fulls/distanciamento_img.jpg'),
    video:
      'https://jvisionstorage.blob.core.windows.net/videos-landingpage/JVision-COVID.mp4?st=2020-07-23T21%3A50%3A23Z&se=2035-07-24T21%3A38%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=78tyLFes3AMR5PGLYGHj10QanWkZRD9k6RVJyjwxaeA%3D',
    thumbnail: require('../assets/images/thumbs/01.jpg'),
    title: 'Distânciamento Social',
    desc: 'Alerta de aglomerações em tempo real',
  },
  {
    src: require('../assets/images/fulls/fr_img.jpg'),
    video:
      'https://jvisionstorage.blob.core.windows.net/videos-landingpage/masks_output.mp4?st=2020-07-23T21%3A51%3A08Z&se=2035-07-28T21%3A51%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=fdh1hOuta9F4zHH4zIc0PbQ4K4LFiksnTSIPgIhFYKw%3D',
    thumbnail: require('../assets/images/thumbs/02.jpg'),
    title: 'Detecção de uso de máscaras',
    desc: 'Volte ao trabalho com confiança',
  },
  {
    src: require('../assets/images/fulls/helmet_img.jpg'),
    video:
      'https://jvisionstorage.blob.core.windows.net/videos-landingpage/helmet_2.mp4?st=2020-07-23T21%3A45%3A53Z&se=2030-01-01T03%3A00%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=tmTVEECpCkw%2BCoFaEnsLKDDypf5ARy%2Bvs9FIRWXpYqk%3D',
    thumbnail: require('../assets/images/thumbs/03.jpg'),
    title: "Monitoramento de uso de EPI's",
    desc: 'Monitoramento inteligente por zona, minimizando alarmes falsos',
  },
  {
    src: require('../assets/images/fulls/tracking.jpg'),
    video:
      'https://jvisionstorage.blob.core.windows.net/videos-landingpage/tracking_video.mp4?st=2020-07-23T21%3A51%3A38Z&se=2030-07-24T21%3A51%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=JKjAvoGykxzKweNZ2cylrCONLjYAt79TdxuCGqa5ziw%3D',
    thumbnail: require('../assets/images/thumbs/04.jpg'),
    title: 'Motion Tracking',
    desc:
      'Inteligência Artificial para detectar e acompanhar o que é importante para seu negócio.',
  },
];
const IndexPage = () => (
  <LayoutDefault>
    <Home images={img_set} />
  </LayoutDefault>
);

export default IndexPage;
